import PhotoSwipeLightbox from 'photoswipe/lightbox';

import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import 'photoswipe/style.css';

const $navbarToggle = document.querySelector('.burger')
const $navbar = document.querySelector('.header__menu')
const $closeNavbar = document.querySelector('.close-navbar')

if($navbar){
    $navbar.classList.remove('open')
}

if($navbarToggle&&$navbar){
    $navbarToggle.onclick = ()=>{
        $navbar.classList.add('open')
    }

    if($closeNavbar){
        $closeNavbar.onclick = ()=>{
            $navbar.classList.remove('open')
        }
    
    }
}

const $menuToggles = document.querySelectorAll('.menu__link--mobile.menu__link--toggle')

if($menuToggles){
    $menuToggles.forEach(m=>{
        m.onclick = ()=>{
            m.closest('.menu__item').classList.toggle('submenu-open')
        }
    })
}


if (window.location.pathname.includes('index') || window.location.pathname === '/') {
    const mainSlider = document.querySelector('.main-slider__swiper')



    // init Swiper:
    if (mainSlider) {

        new Swiper(mainSlider, {
            slidesPerView: 1,
            loop: "true",
            // configure Swiper to use modules
            modules: [Navigation, Pagination],
            pagination: {
                el: '.main-slider__pagination',
                bulletClass: 'main-slider__bullet',
                bulletActiveClass: 'main-slider__bullet--active',
                clickable: true,
                //     renderBullet: function (index, className) {
                //         return `
                //   <div class="services__bullet ${className}">

                //   </div>`
                //     }
            },

            // Navigation arrows
            navigation: {
                nextEl: '.main-slider__next',
                prevEl: '.main-slider__prev',
            },
        });
    }



    const setMainPetsSlider = async () => {

        const mainPetsSlider = document.querySelector('.main-pets__swiper')

        if (mainPetsSlider) {

            new Swiper(mainPetsSlider, {
                spaceBetween: 20,
                slidesPerView: "auto",
                loop: true,

                // configure Swiper to use modules
                modules: [Navigation, Pagination],
                pagination: {
                    el: '.main-pets__pagination',
                    bulletClass: 'main-pets__bullet',
                    bulletActiveClass: 'main-pets__bullet--active',
                    clickable: true,
                    //     renderBullet: function (index, className) {
                    //         return `
                    //   <div class="services__bullet ${className}">

                    //   </div>`
                    //     }
                },

                // Navigation arrows
                navigation: {
                    nextEl: '.main-pets__next',
                    prevEl: '.main-pets__prev',
                },

            });
        }
    }


    const setMainExperts = async () => {


            const lightbox = new PhotoSwipeLightbox({
                // may select multiple "galleries"
                gallery: '#galery',
              
                // Elements within gallery (slides)
                children: 'a',
              
                // setup PhotoSwipe Core dynamic import
                pswpModule: () => import('photoswipe')
              });
              lightbox.init();

    }


    setMainPetsSlider()
    setMainExperts()


}

