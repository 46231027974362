import Swiper from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation } from 'swiper/modules';
const zonesSlider = document.querySelector('.zones-slider__swiper')

const setZone =  () => {
    // init Swiper:
    if (zonesSlider) {

        new Swiper(zonesSlider, {
            slidesPerView: 1,
            loop: "true",
            // configure Swiper to use modules
            modules: [Navigation],


            // Navigation arrows
            navigation: {
                nextEl: '.zones-slider__next',
                prevEl: '.zones-slider__prev',
            },
        });
    }
}

setZone()

